<template>
  <div>
    <slot :toggle="toggle" :is-dark="isDark">
      <v-btn
        @click="toggle()"
        :icon="isDark ? 'heroicons:sun' : 'heroicons:moon'"
      />
    </slot>
  </div>
</template>

<script setup>
const { toggle, isDark } = useCustomTheme();
</script>

<style></style>
